@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
}

:root {
  --primary-color: #ffca1d;
  --secondary-color: #4b4aef;
  --white-color: #ffffff;
  --black-color: #000000;

  /* scrollbar colors */
  --scrollbar-track-color: #f1f1f1;
  --scrollbar-thumb-color: #888;
  --scrollbar-thumb-hover-color: #555;

  /* SECTIONS STYLES */
  --global-sections-max-width: 1600px;
  --global-sections-padding: 0px 40px;

  /* SPACING */
  --sections-seperator-space: 120px;
  --title-description-up-seperator-space: 20px;
  --title-description-bottom-seperator-space: 80px;
  --cards-gap-seperator-space: 40px;

  /* text-styles colors | sizes */
  /* title */
  --txt-title-primary-color: var(--primary-color);
  --txt-title-secondary-color: var(--secondary-color);
  --txt-title-size: 36px;
  --txt-title-lineHeight: 42px;
  /* title-description */
  --txt-title-description-color: #696984;
  --txt-title-description-size: 24px;
  --txt-title-description-lineHeight: 30px;
  /* subtitle */
  --txt-subtitle-color: var(--secondary-color);
  --txt-subtitle-size: 24px;
  --txt-subtitle-lineHeight: 24px;
  /* description */
  --txt-description-color: #585858;
  --txt-description-size: 20px;
  --txt-description-lineHeight: 24px;
}

/* ER -> EduRate */
.er-wrapper {
  max-width: var(--global-sections-max-width);
  padding: var(--global-sections-padding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.er-title {
  color: var(--txt-title-secondary-color);
  font-size: var(--txt-title-size);
  line-height: var(--txt-title-lineHeight);
  text-align: center;
}

.er-title-mark {
  color: var(--txt-title-primary-color);
  font-size: var(--txt-title-size);
  line-height: var(--txt-title-lineHeight);
}

.er-title-description {
  color: var(--txt-title-description-color);
  font-size: var(--txt-title-description-size);
  line-height: var(--txt-title-description-lineHeight);
  text-align: center;
}

.er-subtitle {
  color: var(--txt-subtitle-color);
  font-size: var(--txt-subtitle-size);
  line-height: var(--txt-subtitle-lineHeight);
}

.er-description {
  color: var(--txt-description-color);
  font-size: var(--txt-description-size);
  line-height: var(--txt-description-lineHeight);
}

@media (max-width: 1300px) {
  :root {
    --global-sections-padding: 0px 35px;

    /* SPACING */
    --sections-seperator-space: 100px;
    --title-description-up-seperator-space: 18px;
    --title-description-bottom-seperator-space: 70px;
    --cards-gap-seperator-space: 35px;

    /* text-styles colors | sizes */
    /* title */
    --txt-title-size: 34px;
    --txt-title-lineHeight: 40px;
    /* title-description */
    --txt-title-description-size: 22px;
    --txt-title-description-lineHeight: 26px;
    /* subtitle */
    --txt-subtitle-size: 22px;
    --txt-subtitle-lineHeight: 20px;
    /* description */
    --txt-description-size: 18px;
    --txt-description-lineHeight: 20px;
  }
}

@media (max-width: 920px) {
  :root {
    --global-sections-padding: 0px 30px;

    /* SPACING */
    --sections-seperator-space: 80px;
    --title-description-up-seperator-space: 15px;
    --title-description-bottom-seperator-space: 60px;
    --cards-gap-seperator-space: 30px;

    /* text-styles colors | sizes */
    /* title */
    --txt-title-size: 32px;
    --txt-title-lineHeight: 38px;
    /* title-description */
    --txt-title-description-size: 20px;
    --txt-title-description-lineHeight: 24px;
    /* subtitle */
    --txt-subtitle-size: 20px;
    --txt-subtitle-lineHeight: 22px;
    /* description */
    --txt-description-size: 17px;
    --txt-description-lineHeight: 20px;
  }
}

@media (max-width: 620px) {
  :root {
    --global-sections-padding: 0px 25px;

    /* SPACING */
    --sections-seperator-space: 70px;
    --title-description-up-seperator-space: 12px;
    --title-description-bottom-seperator-space: 50px;
    --cards-gap-seperator-space: 25px;

    /* text-styles colors | sizes */
    /* title */
    --txt-title-size: 28px;
    --txt-title-lineHeight: 32px;
    /* title-description */
    --txt-title-description-size: 18px;
    --txt-title-description-lineHeight: 25px;
    /* subtitle */
    --txt-subtitle-size: 18px;
    --txt-subtitle-lineHeight: 25px;
    /* description */
    --txt-description-size: 16px;
    --txt-description-lineHeight: 22px;
  }
}

@media (max-width: 480px) {
  :root {
    --global-sections-padding: 0px 20px;

    /* SPACING */
    --sections-seperator-space: 60px;
    --title-description-up-seperator-space: 10px;
    --title-description-bottom-seperator-space: 40px;
    --cards-gap-seperator-space: 20px;

    /* text-styles colors | sizes */
    /* title */
    --txt-title-size: 24px;
    --txt-title-lineHeight: 28px;
    /* title-description */
    --txt-title-description-size: 16px;
    --txt-title-description-lineHeight: 19px;
    /* subtitle */
    --txt-subtitle-size: 17px;
    --txt-subtitle-lineHeight: 24px;
    /* description */
    --txt-description-size: 15px;
    --txt-description-lineHeight: 23px;
  }
}
